import { _adapters } from "chart.js";
import Spacetime from "spacetime";
const FORMATS = {
  default: "M/d/yyyy",
  datetime: "MMM d, yyyy, h:mm:ss a",
  millisecond: "h:mm:ss.SSS a",
  second: "h:mm:ss a",
  minute: "h:mm a",
  hour: "h a",
  day: "MMM d",
  week: "MMM d, yyyy",
  month: "MMM yyyy",
  quarter: "'Q'Q '-' yyyy",
  year: "yyyy"
};
const isKnownFormat = (format) => format in FORMATS;
const spacetime = (value, weekStart) => Spacetime(value, "UTC", {
  weekStart
});
const adapter = {
  formats: function() {
    return FORMATS;
  },
  init: function() {
  },
  parse: function(value) {
    const s = spacetime(value);
    return s.isValid() ? spacetime(s).toNativeDate().getTime() : null;
  },
  format: function(time, format) {
    const s = spacetime(time);
    if (isKnownFormat(format)) {
      return s.unixFmt(FORMATS[format]);
    } else {
      return s.unixFmt(format);
    }
  },
  add: function(time, amount, unit) {
    return spacetime(time).add(amount, unit).toNativeDate().getTime();
  },
  diff: function(max, min, unit) {
    return spacetime(min).diff(spacetime(max))[`${unit}s`];
  },
  startOf: function(time, unit, weekStart) {
    if (unit === "isoWeek") {
      const dateTime = spacetime(
        time,
        Math.trunc(Math.min(Math.max(0, Number(weekStart)), 6)) || 0
      );
      return dateTime.startOf("week").toNativeDate().getTime();
    } else {
      return spacetime(time).startOf(unit).toNativeDate().getTime();
    }
  },
  endOf: function(time, unit) {
    return spacetime(time).endOf(unit).toNativeDate().getTime();
  }
};
_adapters._date.override(adapter);
export {
  FORMATS,
  adapter
};
